import React from 'react';
import { useStaticQuery, graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import Testimonial from '../components/Testimonial';
import Layout from '../layouts/Layout';
import SEO from '../components/SEO';

const Container = styled.div``;

const TestimonialsPage: React.FC<PageProps> = function (props) {
  const { location } = props;
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "testimonial" } }) {
        edges {
          node {
            name
            childMdx {
              frontmatter {
                title
                content
                name
                information
              }
            }
          }
        }
      }
    }
  `);

  const { edges } = data?.allFile;

  return (
    <Layout>
      <SEO title="Testimonials" path={location?.pathname} />
      <Container>
        {edges?.map(edge => (
          <Testimonial key={edge.node.name} testimonial={edge.node} />
        ))}
      </Container>
    </Layout>
  );
};

export default TestimonialsPage;
