import React from 'react';
import styled from 'styled-components';
import MDX from '../cms/MDX';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid blue;
`;

const Content = styled.div`
  font-size: 2rem;
`;

const Name = styled.span``;

const Information = styled.span``;

interface Props {
  testimonial: any;
}

const Testimonial: React.FC<Props> = function (props) {
  const { testimonial } = props;

  return (
    <Wrapper>
      <Content>
        <MDX>{testimonial.childMdx.frontmatter.content}</MDX>
      </Content>
      <Name>{testimonial.childMdx.frontmatter.name}</Name>
      <Information>{testimonial.childMdx.frontmatter.information}</Information>
    </Wrapper>
  );
};

export default Testimonial;
